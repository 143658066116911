<template>
    <div class="form">
        <el-from v-model="form">
            <el-row :gutter="25">
                <el-col :span="6">
                    <el-form-item label="发票抬头">
                        <el-input v-model="form.invoice_header"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-button type="primary" @click="search">搜索</el-button>
                </el-col>
            </el-row>
        </el-from>
    </div>
    <div class="table">
        <c-table ref="tableRef" :tableDataUrl="tableDataUrl" :border="true" :isLoadData="true">
            <el-table-column type="index" width="55" label="序号" :align="'center'" />
            <el-table-column prop="invoice_header" label="发票抬头" />
            <el-table-column prop="taxpayer_identification_number" label="纳税人识别号" />
            <el-table-column prop="invoice_type" label="发票类型">
                <template #default="scope">
                    <span v-if="scope.row.invoice_type == 1">
                        <el-tag effect="dark" type="warning">
                            专用增值税发票
                        </el-tag>
                    </span>
                    <span v-if="scope.row.invoice_type == 2">
                        <el-tag effect="dark" type="danger">
                            专用增值税发票
                        </el-tag>
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="invoice_money" label="开票金额" />
            <el-table-column prop="basic_account" label="开户账户" />
            <el-table-column prop="bank_name" label="开户行" />
            <el-table-column prop="addressee_phone" label="收件人手机号" />
            <el-table-column label="状态">
                <template #default="scope">
                    <span v-if="scope.row.status == 10">
                        <el-tag type="info">待审核</el-tag>
                    </span>
                    <span v-if="scope.row.status == 20">
                        <el-tag type="info">风控审核通过</el-tag>
                    </span>
                    <span v-if="scope.row.status == 30">
                        <el-tag type="danger">风控审核不通过</el-tag>
                    </span>
                    <span v-if="scope.row.status == 40">
                        <el-tag type="info">开票中</el-tag>
                    </span>
                    <span v-if="scope.row.status == 50">
                        <el-tag type="danger">拒绝开票</el-tag>
                    </span>
                    <span v-if="scope.row.status == 60">
                        <el-tag type="success">开票完成</el-tag>
                    </span>
                    <span v-if="scope.row.status == 70">
                        <el-tag type="warning">撤销申请</el-tag>
                    </span>
                    <span v-if="scope.row.status == 80">
                        <el-tag type="info">已红冲</el-tag>
                    </span>
                </template>
            </el-table-column>
        </c-table>
    </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import cTable from '@/components/CustomTable'

const { proxy } = getCurrentInstance() // 当前实例
const tableDataUrl = proxy.$api.invoice.record
const invoice_header = ref(null)
const form = ref({
    invoice_header: ''
})
const search = () => {
    let params = {}
    if (invoice_header.value) {
        params['invoice_header'] = invoice_header.value
    }

    proxy.$refs.tableRef.search(params)
}
</script>